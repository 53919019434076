import React from "react";

export const Heading = () => {
  return (
    <div className="glitching" id="hero">
      <h1 className="main-title font-Sha">JORGEPARDO.DEV</h1>
      <h1 className="main-title font-Sha">JORGEPARDO.DEV</h1>
      <h1 className="main-title font-Sha">JORGEPARDO.DEV</h1>
      <h1 className="main-title font-Sha">JORGEPARDO.DEV</h1>
      <h1 className="main-title font-Sha">JORGEPARDO.DEV</h1>
    </div>
  );
};
