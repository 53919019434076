import "./App.css";
import { Main } from "./components/common/main";

function App() {
  return (
    <div>
      <Main />
    </div>

  );
}

export default App;
