import React from "react";
import { Info } from "../components/common/info.js";

export const Profile = () => {
  return (
    <div className=" bg-TW-bg-dark">
      <Info />
    </div>
  );
};
